<template>
    <InfoCardSlot
      class="company-details"
      title="Your Company Details"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      :hideFooter="true">
      <template #custom-content>
        <div class="company-details__content flex-column h-100 w-100">
          <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
            Welcome, it’s great to see you - here’s the best things you can do on Iknowa right now!
          </Typography>
          <!-- NOTE: Create reusable Item for this  -->
          <div
            class="company-details__item flex-column gap-0"
            v-for="(item, index) in data" :key="index">
            <Typography variant="h6">{{ item.label }}</Typography>
            <ActionItemComponent
              :label="item.actionLabel"
              fontColor="#FAA500"
              :isReverse="true"
              icon="arrow-right.svg"
              @call-to-action="handleClick(item.key)"/>
          </div>

          <!-- Modal -->
          <InsuranceModal :show="insuranceModal" @close="setInsuranceModal(false)"/>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InsuranceModal from '@/core/components/common/modals/new-designs/InsuranceModal.vue';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Typography from '@/core/components/ui/Typography.vue';

const INSURANCE_METHOD = 'insurance';
const TAX_METHOD = 'tax';
const MEMBERSHIP_METHOD = 'membership';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    ActionItemComponent,
    InsuranceModal
  },

  data() {
    return {
      data: [
        {
          key: INSURANCE_METHOD,
          label: 'Insurances',
          actionLabel: 'Add Insurance details'
        },
        {
          key: TAX_METHOD,
          label: 'Tax & VAT',
          actionLabel: 'Add Tax & VAT Details'
        },
        {
          key: MEMBERSHIP_METHOD,
          label: 'Trade Memberships & Accreditations',
          actionLabel: 'Add Memberships & Accreditations Details'
        },
      ],
      insuranceModal: false
    };
  },

  methods: {
    handleClick(key) {
      if (key === INSURANCE_METHOD) {
        this.setInsuranceModal(true);
      }
    },

    setInsuranceModal(state) {
      this.insuranceModal = state;
    }
  },
});
</script>
<style lang="scss" scoped>
.company-details {
  max-height: unset !important;
  height: auto;

  &__item {
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid #C5D2F2;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
