<template>
  <div class="workstation-setup default-layout-desktop-padding">
    <div class="workstation-setup__main-container flex-column h-100">
      <WelcomeBanner
        header="Welcome to your iknowa HQ, Allar"
        :buttonLabel="getPrimaryButtonLabel"
        :mainButtonIsActive="isFormReady"/>
      <div
        class="workstation-setup__content w-100 h-100 grid"
        :class="{'grid-col-2': !isLimitedCompany}">
        <section class="workstation-setup__container grid h-100 1-100 relative">
          <LockedState v-if="isSubmitted"/>
          <div class="workstation-setup__container flex-column h-100 w-100">
            <CompanyDetailsCard/>
          </div>
          <div class="workstation-setup__container flex-column h-100 w-100" v-if="isLimitedCompany">
            <CompanyDirectorsCard/>
            <YourTeamCard/>
          </div>
        </section>
        <section class="workstation-setup__container h-100 w-100">
          <WorkstationSetupInsightsCard
            :isLimitedCompany="isLimitedCompany"
            :buttonText="getPrimaryButtonLabel"
            :formState="formState"
            :isFormReady="isFormReady"/>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import WelcomeBanner from '@/core/components/layouts/WelcomeBanner.vue';
import { LIMITED_COMPANY } from '@/core/constants/workstation';
import CompanyDetailsCard from '@/modules/workstation-setup/components/CompanyDetailsCard.vue';
import CompanyDirectorsCard from '@/modules/workstation-setup/components/CompanyDirectorsCard.vue';
import LockedState from '@/modules/workstation-setup/components/LockedState.vue';
import WorkstationSetupInsightsCard from '@/modules/workstation-setup/components/WorkstationSetupInsightsCard.vue';
import YourTeamCard from '@/modules/workstation-setup/components/YourTeamCard.vue';
import workstationSetupStatus from '@/modules/workstation-setup/constants';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  components: {
    WelcomeBanner,
    CompanyDetailsCard,
    CompanyDirectorsCard,
    YourTeamCard,
    WorkstationSetupInsightsCard,
    LockedState
  },

  data() {
    return {
      workstationSetupStatus,

      isFormReady: false,

      LIMITED_COMPANY,

      formState: workstationSetupStatus.NOT_READY_TO_SUBMIT
    };
  },

  watch: {
    formState: {
      immediate: true,
      deep: true,
      handler(value) {
        this.isFormReady = value === workstationSetupStatus.READY_TO_SUBMIT;
      }
    }
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation']),

    isLimitedCompany() {
      return this.getTypeWorkstation === LIMITED_COMPANY;
    },

    isSubmitted() {
      return this.formState === workstationSetupStatus.SUBMITTED;
    },

    getPrimaryButtonLabel() {
      const { isSubmitted } = this;

      if (this.isLimitedCompany) {
        if (isSubmitted) {
          return 'company verification submitted';
        }
        return 'Submit company verification';
      }
      if (!isSubmitted) {
        return 'Submit Sole Trader verification';
      }
      return 'sole trader verification submitted';
    }
  }
});
</script>
<style lang="scss" scoped>
.workstation-setup {
  overflow-y: scroll;

  &__content.grid {
    // grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-columns: 2fr 1fr;
  }

  &__content.grid-col-2 {
    grid-template-columns: 1fr 1fr;
  }

  &__container.grid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  &__card {
    max-height: unset;
  }
}
</style>
