<template>
    <div class="locked-state h-100 w-100 absolute flex-row ai-c jc-c">
      <div class="locked-state__locked-item relative default-card flex-column ai-c jc-c text-center">
        <Icon iconName="locked.svg"/>
        <Typography variant="h6">Workstation Locked For Verification</Typography>
        <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
          Whilst we verify your company we have locked the dashboard. As soon as verification is complete you’ll receive an email.
        </Typography>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    Icon
  }
});
</script>
<style lang="scss" scoped>
  .locked-state {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #F1F6FB;
      opacity: 0.7;
      z-index: 1;
    }

    &__locked-item {
      max-height: unset !important;
      height: auto;
      width: 100%;
      max-width: 370px;
      z-index: 1;
    }
  }
</style>
