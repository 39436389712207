<template>
    <InfoCardSlot
      class="your-team"
      title="Your team"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      actionLabel="Invite New Team Member">
      <template #custom-content>
        <div class="your-team__content flex-column h-100 w-100">
          <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
            Welcome, it’s great to see you - here’s the best things you can do on Iknowa right now!
          </Typography>
          <!-- NOTE: Create reusable Item for this  -->
          <el-scrollbar>
            <div class="your-team__scrollbar-content flex-column">
              <div
                class="your-team__item flex-row ai-c jc-sb w-100"
                v-for="(item, index) in data" :key="index">
                <div class="flex-row ai-c">
                    <UserThumbnail
                        borderRadius="50px"
                        width="45px"
                        height="45px"
                        :key="require(`@/assets/images/box-empty.jpeg`)"
                        :source="require(`@/assets/images/box-empty.jpeg`)"
                        >
                    </UserThumbnail>
                    <Typography variant="p">
                      {{ item.name }}
                    </Typography>
                </div>
                <el-select v-model="optionValue">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import permissionConstant from '@/core/constants/permissions';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    UserThumbnail
  },

  data() {
    return {
      optionValue: '',
      permissionConstant,
      data: [
        {
          name: 'Allar Klein',
        },
        {
          name: 'Keano Chang',
        },
      ],
      options: [
        {
          value: permissionConstant.ADMIN_LABEL,
          label: permissionConstant.ADMIN_LABEL,
        },
        {
          value: permissionConstant.EDIT_LABEL,
          label: permissionConstant.EDIT_LABEL,
        },
        {
          value: permissionConstant.VIEW_LABEL,
          label: permissionConstant.VIEW_LABEL,
        },
      ],
    };
  },
});
</script>
<style lang="scss" scoped>
.your-team {
  max-height: 590px !important;
  height: 100%;

  &__item {
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid #C5D2F2;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
