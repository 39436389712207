<template>
    <InfoCardSlot
      class="company-directors"
      title="Company Director(s)"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      :hideFooter="true">
      <template #custom-content>
        <div class="company-directors__content flex-column h-100 w-100">
          <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
            Welcome, it’s great to see you - here’s the best things you can do on Iknowa right now!
          </Typography>
          <!-- NOTE: Create reusable Item for this  -->
          <div class="company-directors__item flex-column">
            <Button
              class="company-directors__button"
              buttonText="assign myself as a director"
              :isActive="true"
              activeColor="#FFF"
              activeFontColor="#4F55F0"
              @handle-click="onSendVerificationCode"/>
            <Button
              class="company-directors__button"
              buttonText="invite director to iknowa"
              :isActive="true"
              activeColor="#FFF"
              activeFontColor="#4F55F0"
              @handle-click="onSendVerificationCode"/>
          </div>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    Button
  },

  data() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
.company-directors {
  max-height: unset !important;
  height: auto;

  &__item {
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid #C5D2F2;

    &:last-child {
      border-bottom: none;
    }
  }

  &__button {
    border: 1px solid #4F55F0;
  }
}
</style>
