<template>
  <div>
    <AccountInsightsCard
      :data="insightsData"
      title="Setting Up Your Iknowa Workstation"
      secondaryTitle="Your Iknowa account setup"
      @handle-click="onClickItem"
      :hasCustomFooter="true">
        <template #custom-footer>
          <Button :buttonText="buttonText" :isActive="isFormReady"/>
        </template>
    </AccountInsightsCard>

    <!-- MODALS -->
    <IdCheckModal :show="showIdCheckModal" @close="setShowIdCheckModal(false)"/>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import AccountInsightsCard from '@/core/components/common/cards/AccountInsightsCard.vue';
import IdCheckModal from '@/core/components/common/modals/new-designs/IdCheckModal.vue';
import Button from '@/core/components/ui/Button.vue';

const ID_CHECK_METHOD = 'id-check';

export default defineComponent({
  components: {
    AccountInsightsCard,
    IdCheckModal,
    Button
  },

  props: ['isLimitedCompany', 'buttonText', 'formState', 'isFormReady'],

  data() {
    return {
      limitedCompanyData: [
        {
          task: 'Create Workstation',
          label: 'Everything under one roof',
          active: true
        },
        {
          task: 'Assign Director(s)',
          label: 'xxx'
        },
        {
          task: 'Invite Teammates',
          label: 'xxx'
        },
        {
          key: ID_CHECK_METHOD,
          task: 'Add Company Information',
          label: 'xxx'
        },
        {
          task: 'Submit Company for Verificaton',
          label: 'xxx'
        },
      ],
      soleTraderData: [
        {
          task: 'Create Workstation',
          label: 'Everything under one roof',
          active: true
        },
        {
          key: ID_CHECK_METHOD,
          task: 'Add Company Information',
          label: 'xxx'
        },
        {
          task: 'Submit Company for Verificaton',
          label: 'xxx'
        },
      ],

      showIdCheckModal: false,
    };
  },

  computed: {
    insightsData() {
      if (this.isLimitedCompany) {
        return this.limitedCompanyData;
      }
      return this.soleTraderData;
    },
  },

  methods: {
    onClickItem(key) {
      console.log(key, 'key');
      if (key && key === ID_CHECK_METHOD) {
        this.setShowIdCheckModal(true);
      }
    },

    setShowIdCheckModal(state) {
      this.showIdCheckModal = state;
    }
  }
});
</script>
<style lang="scss" scoped>
</style>
